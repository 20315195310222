<template>
  <BusinessesComponent />
</template>

<script>
import BusinessesComponent from '../components/BusinessesComponent.vue'

export default {
  name: 'Businesses',
  components: {
    BusinessesComponent,
  },
}
</script>
