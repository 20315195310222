import { render } from "./BusinessesComponent.vue?vue&type=template&id=e0b5baa2"
import script from "./BusinessesComponent.vue?vue&type=script&lang=js"
export * from "./BusinessesComponent.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "e0b5baa2"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('e0b5baa2', script)) {
    api.reload('e0b5baa2', script)
  }
  
  module.hot.accept("./BusinessesComponent.vue?vue&type=template&id=e0b5baa2", () => {
    api.rerender('e0b5baa2', render)
  })

}

script.__file = "src/components/BusinessesComponent.vue"

export default script