<template>
  <div class="py-4">
    <div class="bg-white w-full py-4 px-7 border-gray-200 border-b-2">
      <button
        class="bg-gray-700 text-white py-2 px-4 flex items-center rounded-full"
        @click="toggleModal()"
      >
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="plus"
          class="svg-inline--fa fa-plus fa-w-14 inline font-light mr-3"
          width="20px"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 448 512"
        >
          <path
            fill="currentColor"
            stroke-width="1%"
            d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"
          ></path>
        </svg>
        Add Business
      </button>
    </div>

    <template v-if="businesses.length == 0">
      <div
        class="border-gray-300 border-2 text-red-500 text-center py-3 mt-3 bg-white"
      >
        No businesses yet.
      </div>
    </template>
    <template v-else>
      <div class="flex-row inline-flex w-full py-4">
        <div class="w-1/5 text-center">Name</div>
        <div class="w-1/5 text-center">Code</div>
        <div class="w-1/5 text-center ">Status</div>
        <div class="w-1/5 "></div>
        <div class="w-1/5"></div>
      </div>

      <div
        class="flex-row inline-flex w-full border-t-2 border-gray-200 bg-white py-3"
        v-for="business in businesses"
        :key="business.id"
      >
        <div class="w-1/5 text-center">
          <router-link
            :to="`/businesses/${business.id}/locations`"
            class="text-blue-400"
          >
            {{ business.business_name }}
          </router-link>
        </div>
        <div class="w-1/5 text-center">{{ business.business_code }}</div>
        <div
          class="w-1/5 text-center capitalize"
          :class="
            business.status === 'active' ? 'text-green-500' : ' text-red-500'
          "
        >
          {{ business.status }}
        </div>
        <div class="w-1/5 text-center">
          <a href="#" class="text-blue-400" @click="editBusiness(business)">
            Edit
          </a>
        </div>
        <div class="w-1/5 text-center">
          <a
            href="#"
            :class="
              business.status === 'active' ? 'text-red-500' : ' text-green-500'
            "
            @click="toggleConfirmationStatus(business)"
          >
            {{ business.status === "active" ? "Deactivate" : "Activate" }}
          </a>
        </div>
      </div>
    </template>

    <div
      class="h-screen w-full fixed top-0 right-0 bg-gray-700 opacity-25"
      v-if="showPopup || showConfirmationPopup || showEditPopup"
    ></div>

    <!-- add business popup -->
    <div
      class="h-screen w-full flex items-center justify-center bg-teal-lightest fixed top-0 overflow-auto right-0 lg:business-modal-lg "
      v-if="showPopup"
    >
      <div
        class="h-screen w-full absolute flex items-center justify-center bg-modal"
      >
        <div class="bg-white rounded shadow pb-8 m-4 max-h-full text-center">
          <div class="mb-4 bg-gray-700 py-4 text-white text-base">
            Add Business
          </div>
          <form @submit.prevent="saveBusiness">
            <div
              class="text-xs lg:business-modal text-gray-600 text-justify px-6"
            >
              <div class="items-center flex flex-col mt-7">
                <div class="flex flex-col mb-6 w-full mt-2">
                  <label
                    class="uppercase font-semibold text-sm text-black"
                    for="business-code"
                  >
                    Business Details
                  </label>
                  <input
                    class="border py-2 px-3 text-grey-darkest mt-1"
                    type="text"
                    placeholder="Business Name"
                    v-model="business.business_name"
                    name="email"
                    autocomplete="off"
                    required
                    id="email"
                  />
                </div>
                <div class="flex flex-col mb-6 w-full mt-2">
                  <label
                    class="uppercase font-semibold text-sm text-black"
                    for="business-code"
                  >
                    Business Type
                  </label>
                  <select
                    class="form-select w-full mr-2 border"
                    required
                    v-model="business.business_type"
                  >
                    <option value="invoiced"> Invoiced </option>
                    <option value="demo"> Demo </option>
                    <option value="stripe"> Stripe </option>
                  </select>
                </div>
                <div class="flex flex-col mb-6 w-full mt-2">
                  <label
                    class="uppercase font-semibold text-sm text-black"
                    for="business-code"
                  >
                    Owner Details
                  </label>
                  <input
                    class="border py-2 px-3 text-grey-darkest mt-1"
                    type="text"
                    placeholder="Name"
                    name="text"
                    autocomplete="off"
                    v-model="business.owner.name"
                    required
                    id="email"
                  />
                  <input
                    class="border py-2 px-3 text-grey-darkest mt-2"
                    type="text"
                    placeholder="Username"
                    name="text"
                    autocomplete="off"
                    v-model="business.owner.username"
                    required
                    id="email"
                  />
                  <input
                    class="border py-2 px-3 text-grey-darkest mt-2"
                    type="text"
                    minlength="6"
                    placeholder="Password"
                    name="text"
                    autocomplete="off"
                    v-model="business.owner.password"
                    required
                    id="email"
                  />
                </div>
                <div class="flex flex-col mb-6 w-full mt-2">
                  <label
                    class="uppercase font-semibold text-sm text-black"
                    for="business-code"
                  >
                    Main Location Details
                  </label>
                  <div class="flex flex-row md:justify-between">
                    <input
                      class="border py-2 px-3 text-grey-darkest mt-1 w-full"
                      type="text"
                      placeholder="Location Name"
                      autocomplete="off"
                      v-model="business.location.name"
                      required
                      id="email"
                    />
                    <input
                      class="border py-2 px-3 text-grey-darkest mt-1 w-full ml-1"
                      type="text"
                      placeholder="Phone Number"
                      autocomplete="off"
                      @change="validatePhone"
                      ref="phone"
                      required
                      v-model="business.location.twilio_phone"
                      id="email"
                    />
                  </div>
                  <textarea
                    placeholder="Review Text"
                    class="w-full mt-2 max-h-sm border-gray-200 border-2 py-1 pl-1"
                    rows="6"
                    v-model="business.location.review"
                  >
                  </textarea>
                </div>
              </div>

              <div role="alert" class="my-3" v-if="errorMsg != ''">
                <div
                  class="border border-red-400 rounded bg-red-100 text-sm px-4 py-3 text-red-700"
                >
                  <p>{{ errorMsg }}.</p>
                </div>
              </div>
            </div>

            <div class="flex justify-center flex-col md:flex-row px-2">
              <button
                class="flex-no-shrink text-white py-2 px-4 rounded-full bg-gray-700 w-full  md:w-1/3 md:mr-3"
                type="submit"
                :disabled="isSubmitted || errorMsg !== ''"
                :class="
                  isSubmitted || errorMsg !== ''
                    ? 'opacity-75 cursor-not-allowed'
                    : ''
                "
              >
                {{ isSubmitted ? "Saving Business..." : "Save Business" }}
              </button>
              <button
                class="flex-no-shrink text-gray-700 border-gray-700 border-2 py-2 px-4 rounded-full w-full mt-2 md:w-1/3 md:ml-3 md:mt-0"
                @click="toggleModal()"
                :disabled="isSubmitted"
                :class="isSubmitted ? 'opacity-75' : ''"
              >
                {{ isSubmitted ? "Please Wait..." : "Cancel" }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!--changing business status confirmation popup -->
    <div
      class="h-screen w-full flex items-center justify-center bg-teal-lightest fixed top-0 overflow-auto right-0 lg:business-modal-lg "
      v-if="showConfirmationPopup"
    >
      <div
        class="h-screen w-full absolute flex items-center justify-center bg-modal"
      >
        <div class="bg-white rounded shadow pb-8 m-4 max-h-full text-center">
          <div class="mb-4 bg-gray-700 p-4 text-white text-base">
            Are you sure to
            {{ business.status === "active" ? "deactivate" : "activate" }}
            <span class="text-blue-400"> {{ business.business_name }} </span>
          </div>
          <form @submit.prevent="changeStatus">
            <div class="flex justify-center flex-col md:flex-row px-2">
              <button
                class="flex-no-shrink w-full text-white py-2 px-4 rounded-full bg-gray-700 md:w-24 md:mr-3 text-center"
                type="submit"
                :disabled="isSubmitted"
                :class="isSubmitted ? 'opacity-75' : ''"
              >
                Yes
              </button>
              <button
                class="flex-no-shrink text-gray-700 border-gray-700 border-2 py-2 px-4 rounded-full w-full mt-2 md:w-24 md:ml-3 md:mt-0"
                @click="showConfirmationPopup = false"
                :disabled="isSubmitted"
                :class="isSubmitted ? 'opacity-75' : ''"
              >
                No
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- edit business info popup -->
    <div
      class="h-screen w-full flex items-center justify-center bg-teal-lightest fixed top-0 overflow-auto right-0 lg:business-modal-lg "
      v-if="showEditPopup"
    >
      <div
        class="h-screen w-full absolute flex items-center justify-center bg-modal"
      >
        <div class="bg-white rounded shadow pb-8 m-4 max-h-full text-center">
          <div class="mb-4 bg-gray-700 py-4 text-white text-base">
            Edit Business
          </div>
          <form @submit.prevent="updateBusiness">
            <div
              class="text-xs lg:business-modal text-gray-600 text-justify px-6"
            >
              <div class="items-center flex flex-col mt-7">
                <div class="flex flex-col mb-6 w-full mt-2">
                  <label
                    class="uppercase font-semibold text-sm text-black"
                    for="business-code"
                  >
                    Business Name
                  </label>
                  <input
                    class="border py-2 px-3 text-grey-darkest mt-1"
                    type="text"
                    v-model="business_name"
                    :placeholder="business.business_name"
                    name="email"
                    autocomplete="off"
                    id="email"
                  />
                </div>
                <div class="flex flex-col mb-6 w-full mt-2">
                  <label
                    class="uppercase font-semibold text-sm text-black"
                    for="business-code"
                  >
                    Business Type
                  </label>
                  <select
                    class="form-select w-full mr-2 border py-2 pl-2"
                    required
                    v-model="business.business_type"
                  >
                    <option value="invoiced"> Invoiced </option>
                    <option value="demo"> Demo </option>
                    <option value="stripe"> Stripe </option>
                  </select>
                </div>
              </div>
            </div>

            <div class="flex justify-center flex-col md:flex-row px-2">
              <button
                class="flex-no-shrink text-white py-2 px-4 rounded-full bg-gray-700 w-full  md:w-1/3 md:mr-3"
                type="submit"
                :disabled="isSubmitted"
                :class="isSubmitted ? 'opacity-75' : ''"
              >
                {{ isSubmitted ? "Saving Changes..." : "Save Changes" }}
              </button>
              <button
                class="flex-no-shrink text-gray-700 border-gray-700 border-2 py-2 px-4 rounded-full w-full mt-2 md:w-1/3 md:ml-3 md:mt-0"
                @click="showEditPopup = false"
                :disabled="isSubmitted"
                :class="isSubmitted ? 'opacity-75' : ''"
              >
                {{ isSubmitted ? "Please Wait..." : "Cancel" }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const firebase = require("../firebaseConfig");
import axios from "axios";

export default {
  name: "BusinessesComponent",
  data: () => ({
    businesses: [],
    showPopup: false,
    showConfirmationPopup: false,
    showEditPopup: false,
    businessCodes: [],
    errorMsg: "",
    business_name: "",
    generatedCode: "",
    business: {
      id: "",
      name: "",
      business_type: "invoiced",
      owner: {
        name: "",
        username: "",
        password: "",
      },
      location: {
        name: "",
        twilio_phone: "",
        review: "",
      },
    },
    isSubmitted: false,
  }),
  mounted() {
    this.getBusinesses();
  },
  methods: {
    validatePhone() {
      this.errorMsg = "";
      this.business.location.twilio_phone = this.business.location.twilio_phone
        .replace(/[^0-9]/g, "")
        .replace(/^1+/g, "");
      if (this.business.location.twilio_phone.length != 10) {
        this.errorMsg = `Phone number has to be exactly 10 digit`;
        this.$refs.phone.focus();
        return false;
      }
      return true;
    },
    toggleModal() {
      this.errorMsg = "";
      (this.business = {
        id: "",
        name: "",
        business_type: "invoiced",
        owner: {
          name: "",
          username: "",
          password: "",
        },
        location: {
          name: "",
          twilio_phone: "",
          review: "",
        },
      }),
        (this.showPopup = !this.showPopup);
    },
    toggleConfirmationStatus(business) {
      this.business = business;
      this.showConfirmationPopup = true;
    },
    getBusinesses() {
      firebase.db
        .collection("businesses")
        .onSnapshot({ includeMetadataChanges: true }, (docs) => {
          this.businesses = [];
          docs.forEach((doc) => {
            this.businessCodes.push(doc.data().business_code);
            this.businesses.push({
              id: doc.id,
              ...doc.data(),
            });
          });
        });
    },
    checkCodeExist() {
      return this.businessCodes.includes(this.generatedCode);
    },
    generateBusinessCode() {
      this.generatedCode = (
        "000" + Math.floor(Math.random() * (10000 - 10) + 10)
      ).slice(-4);
    },
    async saveBusiness() {
      this.generateBusinessCode();

      while (this.checkCodeExist()) {
        this.generateBusinessCode();
      }

      this.isSubmitted = true;
      const business = await firebase.db.collection("businesses").add({
        business_code: this.generatedCode,
        business_name: this.business.business_name,
        business_type: this.business.business_type,
        mass_text_active: true,
        mass_text_campaigns: 0,
        message_allotment: 1000,
        message_credits: 0,
        messages_received: 0,
        messages_sent: 0,
        status: "active",
      });

      let email = `${this.generatedCode}${this.business.owner.username}@${process.env.VUE_APP_DOMAIN}`;
      email = email.replace(/\s/g, "");

      const user = await firebase.db.collection("users").add({
        business_code: this.generatedCode,
        location_name: this.business.location.name,
        business_id: business.id,
        display_name: this.business.owner.name,
        role: "business-admin",
        status: "active",
        username: this.business.owner.username,
      });

      await axios({
        url: `${process.env.VUE_APP_FIREBASE_FUNCTIONS_URL}/createUser`,
        method: "POST",
        data: {
          email: email,
          password: this.business.owner.password,
          uid: user.id,
        },
      });

      const location = await firebase.db
        .collection("businesses")
        .doc(business.id)
        .collection("locations")
        .add({
          location_name: this.business.location.name,
          twilio_number: this.business.location.twilio_phone,
          location_messages_received: 0,
          location_messages_sent: 0,
          review_text: this.business.location.review,
          status: "active",
          location_mass_text_campaigns: 0,
        });

      await firebase.db
        .collection("users")
        .doc(user.id)
        .update({
          location_id: location.id,
        });

      this.isSubmitted = false;
      this.showPopup = false;
    },
    async changeStatus() {
      await firebase.db
        .collection("businesses")
        .doc(this.business.id)
        .update({
          status: this.business.status === "active" ? "inactive" : "active",
        });
      this.showConfirmationPopup = false;
    },
    editBusiness(business) {
      this.isSubmitted = false;
      this.showEditPopup = true;
      this.business = business;
    },
    async updateBusiness() {
      await firebase.db
        .collection("businesses")
        .doc(this.business.id)
        .update({
          ...(this.business_name != "" && {
            business_name: this.business_name,
          }),
          business_type: this.business.business_type,
        });
      this.showEditPopup = false;
      this.business_name = "";
    },
  },
};
</script>
