import { render } from "./Businesses.vue?vue&type=template&id=4e55b1b0"
import script from "./Businesses.vue?vue&type=script&lang=js"
export * from "./Businesses.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "4e55b1b0"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('4e55b1b0', script)) {
    api.reload('4e55b1b0', script)
  }
  
  module.hot.accept("./Businesses.vue?vue&type=template&id=4e55b1b0", () => {
    api.rerender('4e55b1b0', render)
  })

}

script.__file = "src/views/Businesses.vue"

export default script